<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Edytuj parametr</h3>
        </header>
        <div class="p-fluid p-formgrid p-grid">

            <div class="p-field p-col-12">
                <label for="name">{{content.description}}</label>
                <InputNumber id="name" type="text" v-model="content.value" placeholder="Wartość"/>
            </div>
        </div>

        <div class="p-field p-col-12 p-md-6 fa-pull-right">
            <Button type="button" class="p-button-secondary" label="Wróć" v-on:click="goBack"/>
            <Button type="button" label="Zapisz" v-on:click="click"/>
        </div>
    </div>
</template>

<script>
    import ParametersService from "../../services/parameters.service";

    export default {
        inject: ['global'],
        name: "EditParameter",
        props: {
            initialCntent: {
                // eslint-disable-next-line vue/require-valid-default-prop
                type: Object, default: {
                    name: "",
                    permissions: [],
                }
            },
        },
        data() {
            return {
                selectedPermissions: [],
                permissions: '',
                content: this.initialCntent,
            };
        },
        methods: {
            click: function () {
                this.content.description = undefined;
                this.content.value = this.content.value.toString();
                ParametersService.editParameter(this.content, this.$route.params.parameterId).then(
                    () => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sukces',
                            detail: 'Poprawnie zapisano parametr',
                            life: 3000
                        });
                        this.$router.push({path: `${this.global.state.instancePatch}/parametry/lista`});
                    },
                    (error) => {
                        this.content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            },
            goBack: function () {
                this.$router.push({path: `${this.global.state.instancePatch}/parametry/lista`});
            }
        },
        mounted() {
            ParametersService.getParameter(this.$route.params.parameterId).then(
                (response) => {
                    this.content = response.data;
                    this.content.id = undefined;
                    this.content.createdAt = undefined;
                    this.content.key = undefined;
                    this.content.type = undefined;
                    this.content.updatedAt = undefined;
                },
                (error) => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    };
</script>
