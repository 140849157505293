import api from './api';

const API_URL = `/parameters`;

class UnitService {
    getAllParameters() {
        return api.get(API_URL);
    }

    editParameter(content, id) {
        return api.patch(`${API_URL}/${id}`, content);
    }

    getParameter(id) {
        return api.get(`${API_URL}/${id}`
        );
    }

}

export default new UnitService();
